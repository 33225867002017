.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  opacity: 0.3;
}

.popperInteractive {
  top: 55px !important;
}

.btnOverlay {
  z-index: 10001;
  box-shadow: 0 0 0 9999px rgba(39, 37, 34, 0.3);
  width: 140px;
  height: 140px;
  border-radius: 50%;
  position: fixed;
  top: 24px;
  right: 0;
}

.tooltip svg {
  display: none;
}

.primaryBtnOuterWrap {
  display: flex;
  justify-content: flex-end;
}

.primaryBtnWrap {
  width: min-content !important;
}

@media (max-width: 767px) {
  .btnOverlay {
    top: -40px;
    width: 144px;
    height: 144px;
  }
  .popperInteractive {
    top: 50px !important;
  }
}
