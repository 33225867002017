.desktopModalPaper {
  width: 565px !important;
  border-radius: 20px 20px 16px 16px !important;
}

.icon {
  width: 100% !important;
  background-color: #edebf7;
}

.carousel {
  width: 100%;
  overflow: hidden;
  padding-bottom: 86px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.85);
  margin: 32px 32px 12px 32px;
}

.description {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #6d675f;
  margin: 0 32px;
}

.carouselDots {
  background: #f2f2f2 !important;
  border-radius: 17px;
  bottom: 28px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 11px;
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  padding: 10px 0 9px 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 120px !important;
}

.carouselDots li {
  border-radius: 50%;
  margin: 0 !important;
  width: 8px !important;
  height: 12px !important;
}

.customDot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
}

.nonActiveDot {
  background-color: #d7d7d7 !important;
}

.activeDot {
  background-color: #4a3ab1 !important;
}

.featureHeading {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  height: 63px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeIcon {
  color: rgba(0, 0, 0, 0.85);
}

.positonDiv {
  width: 24px;
}

.slide {
  overflow-y: auto;
  max-height: 450px;
}

.singleSlide {
  overflow-y: auto;
  max-height: 500px;
  padding-bottom: 32px;
}

.singleGuideFooter {
  height: 126px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;
}

@media (max-width: 767px) {
  .modalPaper {
    height: 85vh !important;
    overflow-y: hidden !important;
  }

  .carousel {
    padding-bottom: 86px !important;
  }

  .title {
    margin: 28px 16px 12px 16px;
    font-size: 22px;
  }

  .description {
    margin: 0 16px;
    font-size: 16px;
  }

  .carousel {
    padding-bottom: 140px;
  }

  .slide {
    overflow-y: auto;
    height: calc(85vh - 63px - 100px);
    max-height: unset;
    -webkit-overflow-scrolling: touch; /* Avoid having to use 2 finger scroll on iOS    */
  }

  .singleSlide {
    overflow-y: auto;
    height: calc(85vh - 63px);
    max-height: unset;
  }

  .singleGuideFooter {
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
  }
}
