.container {
  overflow-y: auto;
}

.mainIcon {
  width: 100%;
  margin-bottom: 24px;
}

.title {
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
}

.secondaryTitle {
  color: #493ab1;
}

.description {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 20px 54px 48px 52px;
  color: #6d675f;
}

@media (max-width: 768px) {
  .container {
    height: calc(85vh - 74px);
    overflow-y: auto;
  }

  .mainIcon {
    margin-top: 62px;
    width: 100%;
  }

  .description {
    margin: 17px 31px 24px 29px;
  }
}
