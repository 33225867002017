.container {
  padding-top: 61px;
}

.primaryTitle {
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
}

.secondaryTitle {
  color: #493ab1;
}

.listIcon {
  width: 44.99px;
  height: 46px;
}

.listWrap {
  padding: 40px 60px 16px 60px;
}

.listItem {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.itemTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #272522;
  margin-bottom: 4px;
}

.itemDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(39, 37, 34, 0.5);
}

@media (max-width: 768px) {
  .container {
    height: calc(85vh - 74px);
    overflow-y: auto;
    padding-top: 80px;
  }

  .listWrap {
    padding: 40px 16px 0 16px;
    max-width: 360px;
    margin: 0 auto;
  }

  .listItem {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 36px;
  }
}
