.card{
    padding: 20px 15px 10px 15px;
    margin: 15px 5px 15px 5px;
    width: fit-content;
}

.disclaimer{
    font-size: 10px;
}

.sticky {
    padding-bottom: 20px;
    position: sticky;
    padding: 45px 0 0 0;
    top: 0px;
    z-index: 100;
    background-color: #ffffff ;
}

.clear_filter{
    margin: 10px 0;
    cursor: pointer;
    padding-left: 60px;
}

.dm_font {
    font-family: DM Sans;
}

.stat_table_layout {
    padding-left: 10px;
}

.flex_row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 10px;
}

.card_header {
    color: #6c5dd3;
    font-size: 18px;
    line-height: 24px;
    margin: 20px 0 -10px 0;
    font-weight: 800;
    color: #212121;
}

.black_header{
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
}

.checklist_row {
    justify-content: flex-start;
    font-size: 14px;
    line-height: 18px;
    margin: 10px 0 10px 0;
    align-items: center;
}

.checkbox{
    margin: 0 10px 0 10px;
}

.form_card{
    padding: 20px;
    border-radius: 4px;
    border: solid 1px #e5e3dd;
    background-color: white;
    width: 100% !important;
}

.bold_black_large_text {
    font-size: 24px;
    color: #212121;
    margin: 0 0 25px 0;
    font-weight: 600;
}


.row {
    margin: 15px 0 30px 0;
}

.full_width_input {
    display: inline-block;
    width: 100%;
}

.black_small_text{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #666666;
}

.card_margin{
    padding: 0 15px 0 15px !important;
}

@media screen and (max-width: 780px) {
    .sticky {
        padding-bottom: 0;
        position: relative;
    }

    .card_header {
        font-size: 14px;
    }

    .form_card {
        padding: 5px 10px;
    }

    .stat_table_layout {
        width: 90vw !important;
        padding-left: 10px !important;
    }

    .card_margin{
        margin: 0 0 15px 8px;
        padding: 0 !important;
    }
}