.root {
    min-width: 200px;
}

.body {
    display: flex;
    flex-direction: column;
}

.sampleImageContainer {
    width: 100%;
    display: flex;
}

.sampleImage {
    width: 100%;
    min-height: 100px;
    background-color: #eee;
    max-width: min(300px, 95vw);
    margin: auto;
    border: 1px solid #ccc7;
    border-radius: 4px;
}
